<template>
  <div class="box toggle-panel" ref="me" v-if="layout == 'box'">
    <div class="box-header with-border clicable no-select">
      <div
        class="h4 box-title"
        @click.stop.prevent="toggle"
        data-testid="toggle-panel-btn"
      >
        <i :class="[icon.before, 'icon-before']" v-if="icon.before"></i>
        <slot name="title">
          {{ $t(title) }}
          <ToolTip v-if="hint" :title="$t(hint)" />
        </slot>
        <div
          class="pull-right"
          style="padding: 0 5px;display:inline-block;width:20px;"
        >
          <i class="fa fa-angle-right" v-if="iCollapsed"></i>
          <i class="fa fa-angle-down" v-else></i>
        </div>
      </div>
      <div class="box-tools pull-right">
        <button class="btn btn-box-tool" data-widget="collapse">
          <i class="fa" :class="icon.collapse"></i>
        </button>
      </div>
    </div>
    <div class="box-body fade-in"><slot></slot></div>
  </div>
  <div v-else-if="layout == 'simple'" class="simple">
    <label class="clicable" @click.stop.prevent="iCollapsed = !iCollapsed"
      >{{ $t(title) }}
      <i :class="iCollapsed ? 'fa fa-chevron-down' : 'fa fa-chevron-up'"></i>
    </label>
    <div v-if="!iCollapsed"><slot></slot></div>
  </div>
</template>

<script>
import ToolTip from "@/components/tooltip.vue";
export default {
  name: "TogglePanel",
  components: {
    ToolTip
  },

  props: {
    layout: {
      type: String,
      default: "box",
      required: false
    },
    collapsed: {
      // original option (requested from parent)
      type: Boolean,
      default: true,
      required: false
    },
    title: {
      type: String,
      default: "Untitled",
      required: false
    },
    icon: {
      type: Object,
      default: () => {
        return { collapse: "fa-minus", expand: "fa-plus" };
      },
      required: false
    },
    persistent: {
      type: Boolean,
      default: true,
      required: false
    },
    hint: {
      type: String,
      default: "",
      required: false
    }
  },
  data() {
    return {
      iCollapsed: true
    };
  },
  methods: {
    toggle() {
      $(this.$refs.me).boxWidget("toggle");
    },
    restore() {
      if (this.persistent) {
        let state = JSON.parse(
          window.localStorage.getItem(this.$options.name) || "{}"
        );
        if (this.title in state) {
          this.iCollapsed = state[this.title];
        } else {
          this.iCollapsed = this.collapsed;
        }
      } else {
        this.iCollapsed = this.collapsed;
      }
    },
    async save() {
      if (this.persistent) {
        let state = JSON.parse(
          window.localStorage.getItem(this.$options.name) || "{}"
        );
        state[this.title] = this.iCollapsed;
        window.localStorage.setItem(this.$options.name, JSON.stringify(state));
      }
    }
  },
  watch: {
    iCollapsed(n) {
      if (this.iCollapsed != this.collapsed) {
        this.$emit("changed", n);
        this.save();
      }
    },
    collapsed() {
      this.iCollapsed = this.collapsed;
      if (this.$refs.me) {
        $(this.$refs.me).boxWidget(this.iCollapsed ? "collapse" : "expand");
      }
    }
  },
  mounted() {
    let self = this;
    self.restore();
    if (this.layout == "box") {
      let $el = $(self.$refs.me)
        .boxWidget({
          animationSpeed: 0,
          collapseIcon: self.icon.collapse || "fa-minus",
          expandIcon: self.icon.expand || "fa-pluss"
        })
        .on("collapsed.boxwidget", (e) => {
          e.stopPropagation();
          self.iCollapsed = true;
          self.$emit("close");
        })
        .on("expanded.boxwidget", (e) => {
          e.stopPropagation();
          self.iCollapsed = false;
          self.$emit("open");
        });
      $el.boxWidget(self.iCollapsed ? "collapse" : "expand");
    }
  }
};
</script>

<style scoped>
.box {
  border: none;
  box-shadow: none;
  margin-bottom: 0;
}

.box-title {
  font-size: 12pt;
  margin: 0;
  width: 100%;
}

.box-body {
  border: 0;
  border-radius: 0;
  padding: 0;
  margin-bottom: 10px;
}
.collapsed-box:not(:last-child) {
  margin-bottom: 12px;
}

.box-header {
  padding: 6px 2px 6px 8px;
  position: relative;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #f4f4f4;
}

.box-header > .box-tools {
  top: 0;
  right: 0;
}

.box-header > .box-tools > .btn-box-tool {
  color: transparent;
}

.clicable:hover {
  cursor: pointer;
  background-color: #eeea;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.simple > div {
  padding: 8px 10px 4px 10px;
  border: 1px solid lightgray;
}

.icon-before {
  margin-right: 2px;
  font-size: 1.4rem;
  width: 1.4rem;
  text-align: center;
}
</style>
<style>
.toggle-panel.has-sub-panel .toggle-panel {
  padding-left: 1em;
}

.tab-content .toggle-panel .box-header {
  margin-inline: -15px;
}
</style>
